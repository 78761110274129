@import '../../../../scss/theme-bootstrap';

$grid-row-gutter-desktop: 20px;
$grid-row-gutter-mobile: 15px;

.grid-row-formatter {
  position: relative;
  width: 100%;
  &__wrapper {
    width: initial;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &__header {
    width: 100%;
  }
  &__grid {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex-direction: row;
    }
    &-item {
      width: 100%;
    }
    &-item:not(:last-of-type):not(.grid-row-formatter__grid-item--zero-margin) {
      margin-bottom: $grid-row-gutter-mobile;
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
        margin-#{$rdirection}: $grid-row-gutter-desktop;
      }
    }
  }
  &--standard-margin {
    padding: 0 $grid-row-gutter-mobile;
    @include breakpoint($landscape-up) {
      padding: 0 $grid-row-gutter-desktop;
    }
  }
  .grid-row-formatter__header-media {
    .mantle-media-asset img,
    .mantle-media-asset picture,
    .mantle-media-asset video {
      width: auto;
      height: auto;
    }
  }
  .content-block__line {
    display: inline-block;
    width: 100%;
    p {
      line-height: inherit;
    }
  }
}
